<script>
import Modal from "./Modal";
import { autoLogin } from "@/js/applications";
import { mapActions } from 'vuex';

export default {
  components: {
    Modal,
  },
  data: function() {
    return {
      eventX: 0,
      eventY: 0,
    };
  },
  computed: {
    menuConfig: function() {
      return this.$store.state.user.menuAssign || {};
    },
    modalCard: function() {
      return this.$refs.modal.$el.querySelector(".modal-g__container");
    },
  },
  methods: {
    ...mapActions("platforms", ["authStreamate", "authModelcenter"]),
    closeModal() {
      this.$store.state.user.menuAssign = {};
    },
    executeOption(option) {
      const { func, param } = option;
      if (!func) return;

      this[func](...param);
      this.$store.state.user.menuAssign = {};
    },
    openLink(url) {
      window.open(url, "_private").focus();
    },
    openAutoLogin: function(endpoint, platform, body) {
      autoLogin(endpoint, platform, body, this.$store);
    },
    sleep: function(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    modalOpen: async function() {
      await this.sleep(100);
      const modalCard = this.modalCard;
      if (modalCard) {
        const windowsHeight = window.innerHeight || window.innerHeight;
        const cardWidth = modalCard.offsetWidth;
        const cardHeight = modalCard.offsetHeight;
        const totalTop = this.eventY + cardHeight;

        modalCard.style.left = `${this.eventX - cardWidth}px`;
        modalCard.style.position = "absolute";
        if (totalTop > windowsHeight) {
          modalCard.style.top = `${this.eventY - cardHeight}px`;
        } else {
          modalCard.style.top = `${this.eventY}px`;
        }
        modalCard.style.opacity = 1;
      }
    },
  },
  mounted: function() {
    this.eventX = this.menuConfig.event.clientX || 0;
    this.eventY = this.menuConfig.event.clientY || 0;
    this.modalCard.style.opacity = 0;
  },
};
</script>

<template>
  <Modal class="menuAssign" ref="modal" :showMask="true" @onCancel="closeModal" @open="modalOpen">
    <template v-slot:header v-if="menuConfig.title">
      <div class="menuAssign__header">
        <h2 class="menuAssign__title">{{ menuConfig.title }}</h2>
        <a class="menuAssign__button" @click="closeModal"><iconic class="menuAssign__icon menuAssign__icon--header" name="close" /></a>
      </div>
    </template>
    <template v-slot:body>
      <div class="menuAssign__body">
        <div class="menuAssign__options">
          <button
            :class="['menuAssign__option', option.style]"
            v-for="(option, idx) in menuConfig.options"
            :key="idx + option.name"
            @click="executeOption(option)"
          >
            <img class="menuAssign__logo" :src="option.link" :alt="option.name" v-if="option.link" />
            <iconic class="menuAssign__icon" v-else-if="option.icon" :name="option.icon" />
            {{ option.name }}
          </button>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="modal__footer"></div>
    </template>
  </Modal>
</template>

<style lang="scss">
.menuAssign {
  font-size: 0.9em;
  background-color: transparent;
  .modal-g__container {
    width: 190px;
    min-width: fit-content;
  }
  &__header {
    @include Flex(row, space-between);
    padding: $mpadding/1.2 0 $mpadding/2 $mpadding;
  }
  &__header,
  &__body,
  &__options {
    width: 100%;
  }
  &__options {
    @include Flex(column);
  }
  &__option {
    @include Flex(row, flex-start);
    width: 98%;
    height: 31.68px;
    margin: $mpadding/4 0;
    padding: 0 $mpadding/3;
    border: none;
    border-radius: 4px;
    &:hover {
      cursor: pointer;
      background-color: $primary-color;
      font-weight: bold;
    }
  }
  &__button {
    @include Flex(row);
    margin-right: $mpadding;
    width: 37.5px;
    height: 37.5px;
    border-radius: 50%;
    &:hover {
      cursor: pointer;
      background: $primary-color;
    }
  }
  &__icon {
    margin-right: $mpadding/2;
    font-size: 1em;
    &--header {
      margin-right: 0;
      font-size: 0.4em;
      color: $primary-color;
      &:hover {
        font-size: 0.5em;
        transition: 0.2s ease-in-out;
      }
    }
  }
  &__logo {
    width: 15px;
    margin-right: $mpadding/2;
  }
  .jasmin {
    background: $primary-color;
    color: white;
  }
  .streamate {
    background: #21619a;
    color: white;
  }
}
</style>
